.panel-footer {
  display: flex;
  justify-content: end;
  padding: 10px 20px 10px 0px; /* top right bottom left*/
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  background: #F5F5F5;
}
.skip-btn {
  background: transparent;
  color: #2856AF;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  margin-right: 20px;
}
.submit-btn {
  background: #2856AF;
  border-radius: 3px;
  color: #fff;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  padding: 10px 70px 10px 70px;
}