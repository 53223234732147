.ant-image {
  width: 100% !important;
  height: 100% !important;
}
.ant-image-img {
  width: 100% !important;
  height: 100% !important;
  vertical-align: middle;
}
.image-gallery-image {
  height: 100% !important;
  width: 100% !important;
}

/* .image-gallery-image:hover {
  -ms-transform: scale(1.5); 
  -webkit-transform: scale(1.5); 
  transform: scale(1.5);
} */
.image-gallery-left-nav .image-gallery-svg, .image-gallery-right-nav .image-gallery-svg {
  height: 50px;
  width: 60px;
}
.image-gallery-fullscreen-button .image-gallery-svg, .image-gallery-play-button .image-gallery-svg {
  height: 22px;
  width: 22px;
}
.image-gallery-thumbnail-image {
  height: 150px;
  width: 150px;
}
