.sidebar {
    margin: 0;
    width: 140px;
    background-color:#2856AF;
    position: fixed;
    height: 100%;
    overflow: auto;
    text-align: center;
  
  }
  
  .sidebar a {
    display: block;
    color: black;
    text-decoration: none;
  }
   
  .sidebar a.active {
    background-color: #04AA6D;
    color: white;
  }
  
  .sidebar a:hover:not(.active) {
    background-color: #555;
    color: white;
  }
  
  div.content {
    height: 1000px;
  }
  
.userName{
  font-size: 15px;
  margin-top: 10px;
  font-family: 'Roboto';
  color: rgb(238, 238, 238);

}
.list-style{
  list-style: none;
  padding: auto;
  margin-left: 10px;
  position: absolute;
  bottom: 0;

}
.list-style li{
  margin-top: 20px;
}
.help-btn:hover{
  transform: scale(1.1);
}
.help-btn{
  transition: transform .2s; /* Animation */
}
