.multi-step-progress__header {
    width: 100%;
    height: 100%;
    border: 1px solid #2856AF;
    border-radius: 5px;
}
.multi-step-progress__step {
    padding-top: 1%;
    height: 90%;
    width: 10%;
}
.step-name {
    font-size: 1em;
}
.step-icon {
    font-size: 3em;
}
.step-lines {
    height: 2%;
    background-color: black;
    align-self: center;
    margin-bottom: 1%;
    opacity: 0.15;
}