.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
}
.templates {
  display: flex;
  color: #FFFFFF;
  overflow-x: scroll;
}

.templates::-webkit-scrollbar {
  display: none;  /* Safari and Chrome */
}

.template {
  display: flex;
  justify-content: space-between;
  margin-left: 5px;
  border-radius: 5px;
  height: 70px;
  width: 300px;
  border-top-right-radius: 70px;
  border-bottom-right-radius: 70px;
  padding: 5px 50px 5px 10px;
  text-align: center;
  min-width: 270px;
  vertical-align: middle;
}
.template-txt {
  display: flex;
  flex-direction: column;
}
.template-first {
  background: #2856AF;
  border: 1px solid #2856AF;
  box-sizing: border-box;
  box-shadow: 0px 1px 2px rgba(55, 65, 81, 0.08);
  color: #FFFFFF;
  clip-path: polygon(0% 0%, 93% 0%, 100% 50%, 93% 100%, 0% 100%);
}
.template-mid {
  background: #2856AF;
  border: 1px solid #2856AF;
  color: #FFFFFF;
  /*background: #F5F5F5;*/
  /*border: 1px solid #F5F5F5;*/
  box-sizing: border-box;
  box-shadow: 0px 1px 2px rgba(55, 65, 81, 0.08);
  clip-path: polygon(0 0, 93% 0, 100% 50%, 91% 99%, 0 99%, 4% 47%);
}
.template-mid p {
  color: #a0a1a3;
}
.template-last {
  background: #2856AF;
  border: 1px solid #2856AF;
  color: #FFFFFF;
  /*background: #F5F5F5;*/
  /*border: 1px solid #F5F5F5;*/
  box-sizing: border-box;
  box-shadow: 0px 1px 2px rgba(55, 65, 81, 0.08);
  clip-path: polygon(100% 0%, 100% 100%, 0% 100%, 4% 47%, 0% 0%);
}
.template-last p {
  color: #a0a1a3;
}
.exitBtn {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #FFFFFF;
  background: #FF4752;
  border-radius: 3px;
  width: 210px;
  height: 50px;
}
.templates h6 {
  color: #FFFFFF;
}
.exit-arrow {
  margin-right: 20px;
}