.click{
    padding: 10px;  
    box-shadow: 0 1px 1px 1px rgba(0,0,0,0.2); 
    transition: transform .2s; /* Animation */
    width: 300px !important;
}
.click:hover{
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transform: scale(1.1)
}
.btn-more{
    border: none;
    width: 50%;
    font-weight: 600;
    color: hsl(0, 0%, 33%);
}
.prog_text{
    font-family: 'Poppins';
    font-weight: 500;
    font-size:18px;
    color: #2856AF;
}
.markt_head{
    font-weight: 500;
    font-size:16px;
    color: #2856AF;
}
.markt_bottom{
    font-size:15px;
    color: #797979;

}
.start_process{
    width: 300px;
    height: 150px;
}
.upload{
    color: rgb(240, 99, 44);
}
.qc{
    color: #306fec;
}
.dwnload{
    color: rgb(108, 216, 108);
}
.tab{
    padding: auto;
    color: rgb(178, 177, 185);
}
.label_destination{
    margin-left: 250px;
}
.label_source{
    margin-left: 20px;
}
.label_search{
    display: flex;
    margin-top: 5%;
    height: 35%;
    width: 75% !important;
}
.div{
    width: 500px;
    height: 600px;
    margin-left: 47px;
}
.align{
    align-items:center;
}
.input{
    margin: 1% 1% 1% 0;
    font-size: 13px;
    line-height: 20px;
    color: #36404C;

}
.upload{
    width: 300px;
    margin-left: 190px;
}
.button{
    width:300px;
    height: 40px;
    align-items: flex-start;
}
.flex-container {
    display: flex;
    font-family: 'Poppins';
    margin-top: 0.4%;

}

.flex-child {
    flex: 1;
    width: 400px;
}  

.flex-child:first-child {
    margin-right: 10px;
} 

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #2856AF !important;
}

.ant-tabs-ink-bar {
    background-color: #2856AF !important;
    box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.12) !important;
    border-radius: 10px 10px 0px 0px !important;
}

.anticon-filter > svg, .ant-table-column-sorter-up > svg, .ant-table-column-sorter-down > svg{
    fill:#FFFFFF;
}