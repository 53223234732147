
.marketPlace {
    width: 30%;
}
.marketPlace .ant-select-selector{
    border-top: 0 !important;
    border-left: 0 !important;
    border-right: 0 !important;
}

.level-3 {
    display: flex;
    margin: 1.5% 0 0 0;
    width: 93%;
    justify-content: space-between;
    /* height: 5.8%; */
}

.templateContainer {
    /* width: 26%; */
    display: flex;
    /* flex-shrink: 1; */
    margin: 0 10px 5px 0;
    background-color: #FDFDFD;
    border: 0.5px solid #C4C4C4;
    border-radius: 4px;
    color: #E3E3E3;
    height: 10%;
}

.temp-btn  {
    margin: 5px 0 5px 10px; 
    border: 0 !important;
    box-shadow: none !important;
}


.inputFilter {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    margin-top: 0.2%;
}

.inputFilter > .totalCount {
    background: #FFC265;
    border-radius: 3px;
    margin-bottom: 5px;
    /* width: 30%; */
    height: 80%;
    color: #2856AF;
    text-align: center;
    padding: 10px 14px 8px 14px;
    font-weight: 500;
    font-size: 14px;
}

.addBulk {
    /* display: flex; */
    height: 37%;
    width: 85% !important;
    /* justify-content: center; */
    /* border: 0.5px solid #C4C4C4; */
}

.add {
    color: #FFFFFF;
    display: flex;
    
    /* background: linear-gradient(to right, #2856AF 0%, #2856AF 20%, #FFFFFF 0%, #FFFFFF 50%); */
    border: 0.5px solid #C4C4C4;
    border-radius: 3px;
    width: 100%;
}

.addPlaceholder {
    padding: 10px;
    margin-left: 25%;
    color: #C4C4C4;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
}

.excelFile {
    display: flex;
    /* height: 32%; */
    /* width: 85%; */
    justify-content: space-between;
    border: 0.3px solid #2856AF;
    border-radius: 5px;
    margin-top: 1%;
    margin-right: 14%;
    padding: 3px;
}

.backFile {
    border: 0 !important; 
    padding-left: 0 !important;
}


.table-row-light {
    background-color: #FEFEFE;
    text-align: center;
}

.table-row-dark {
    background-color: rgba(130, 157, 209, 0.29)!important;
    text-align: center;
}

.ant-table-cell-fix-left .table-row-dark .ant-table-cell {
    background-color: rgba(130, 157, 209, 0.29)!important;
}

.ant-table-thead {
    border-radius: 5px 5px 0 0;
}

.ant-table-thead .ant-table-cell{
    background-color: #829DD1;
    color: #FFFFFF;
    text-align: center;
}

.popupmodal{
    width: 100%;
    height: 500%;
    border-radius: 2px;
    border-color: #829DD1 solid;
}

.rowClassName1 td:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}
   
.rowClassName1 td:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

.classmain{
    margin-left: 10px;
}

.actions {
    position: absolute;
}

.ant-table-thead th.ant-table-column-sort{
    background-color: #2856AF !important;
}  

.ant-table-thead th.ant-table-column-has-sorters:hover {
    background-color: #2856AF !important;
}

.table-row-dark .ant-table-column-sort {
    background-color: #DBE2F2;
    border-right: 1px solid rgba(29, 55, 170, 0.13);
    border-left: 1px solid rgba(29, 55, 170, 0.13);
}

.table-row-light .ant-table-column-sort {
    background-color: #FEFEFE;
    border-right: 1px solid rgba(29, 55, 170, 0.13);
    border-left: 1px solid rgba(29, 55, 170, 0.13);
}

.table-row-dark .ant-table-cell-fix-left{
    background-color: #DBE2F2;
}

.ant-select-selection-item {
    font-weight: bold !important;
    font-size: medium;
}

.ant-table-body {
    height: 700px !important;
    /* width: 1550px !important; */
}

.ant-table-tbody>tr>td {
    font-weight: normal; 
}

.borderCol {
    border-right: 1px solid rgba(255, 255, 255, 0.13);
    box-shadow: 2px 0px 4px rgba(0, 0, 0, 0.14);
}

.reportClass .ant-modal-header {
    padding-bottom: 0;
}

.ant-table-thead .filterStyle .active, .ant-table-thead .sorterStyle .active{
    border: 0.5px solid #FFC265 !important;
}
