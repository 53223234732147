.app-shell {
    width: 100%;
    height: 100%;
    /* background-color: red; */
}
.app-sidebar {
    width: 13%;
    height: 100%;
    /* background-color: yellow; */
}
.app-route-outlet {
    width: 89%;
    height: 100%;
}