.loader-wrapper{
    width: 60px;
    height: 60px;
}

.loader{
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    border: 10px solid rgb(20, 9, 9);
    border-top-color: blue;
    border-bottom-color: red;
    border-radius: 50%;
    animation: rotate 5s linear infinite;
}

.loader-inner{
    border-top-color:green;
    border-bottom-color:white;
    animation-duration: 2.5s;

}

@keyframes rotate {
    0%{
        transform: scale(1) rotate(360deg);
    }
    50%{
        transform: scale(.8) rotate(-360deg);
    }
    100%{
        transform: scale(1) rotate(360deg);
    }
}