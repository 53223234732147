.panel-content {
  padding: 10px 20px 10px 20px; /* top right bottom left*/
  display: flex;
  flex-wrap: wrap
  /* justify-content: center; */
}
.panel-content form {
  display: flex;
  flex-wrap: wrap;
}
.select-div {
  display: flex;
  flex-direction: column;
  margin-right: 15px;
  margin-left: 15px;
  margin-top: 25px;
  margin-bottom: 25px;
  padding: 0px;
  background: #F5F5F5;
  width: 210px;
  height: 40px;
}
.text-div textarea, input {
  color: #565656;
}
.select-field {
  /* width: 250px;
  height: 40px; */
  background: rgba(255, 255, 255, 0.6);
  border: 0.5px solid #C4C4C4;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 10px 5px 10px 5px;
}

.danger-input {
  border: 1px solid red !important;
}

label {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 12px;
  color: #666666;
  margin-bottom: 5px;
}
.select-field {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #565656
}
.text-div {
  display: flex;
  flex-direction: column;
  margin: 10px;
  padding: 0px;
  background: #F5F5F5;
  box-sizing: border-box;
}
.text-div textarea {
  border: 0.5px solid #C4C4C4;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 5px 0px 5px 10px;
  width: 250px;
}
.lessWordAlert {
  color: red;
}
.noAlert {
  opacity: 0;
}