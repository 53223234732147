.flex-box {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}
.flex-column-dir {
    flex-direction: column;
}
.flex-row {
    flex-direction: row;
}
.flex-sb {
    justify-content: space-between;
}
.flex-sa {
    justify-content: space-around;
}
.flex-start {
    justify-content: flex-start;
}
.flex-end {
    justify-content: flex-end;
}
.flex-ai-start {
    align-items: flex-start;
}
.cus-button {
    padding: 1% 2%;
    color: white;
    border-radius: 3px;
}
.primary {
    background-color: #2856AF;
}
.danger {
    background-color: #FF4752;
}
.transparent {
    background-color: rgb(255, 255, 255, 0.1);
}
.primary:disabled {
    background-color:  #2856AF;
}