.spinner-border{
  width:250px;
  height:250px;
  z-index: 9;
  position: absolute;
  left: 500px; top: 100px;
}

.app-container {
    width: 100%;
    height: 100%;
    /* background-color: yellow; */
}

.horizontal-line {
    height: 1px;
    width: 100%;
    background: lightgrey;
}

.sub-title {
    color: grey;
    font-size: 12px;
}