.download_block{
    width: 50%;
    display: block;
    margin:auto;
}
.down{
    font-family: 'Poppins';
    font-weight: bold;
}
.complete_req{
    background: #2856AF;
    width: 210px;
    height: 50px;
    border-radius: 3px;
    color: #e3e3e3;
    display: block;
    margin:auto;
    margin-top: 20px;
    font-family: 'Poppins';
    transition: transform .2s; /* Animation */
}
.complete_req:hover{ 
    transform: scale(1.1);
    background: #2f65d3;
}
.inp, .out{
    background-color: #f5f5f5;
    border-radius: 3px;
    padding: 10px
}
.downloadFile{
    background: #4BEA8B;
    color: #f8f8f8;
    width: 200px;
    height: 55px;
    border-radius: 2px;
    font-family: 'Poppins';

}
.dwnload-img{
    margin-left: 20px;
}
.file-img{
    margin-right: 10px;

}
.sku_count{
    font-size: 10px;
}