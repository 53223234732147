.right-panel {
  background: #F5F5F5;
  border-radius: 5px;
  /* padding: 20px; */
  width: 850px;
  /* height: fit-content; */
  max-height: 700px;
  overflow-y: auto;
  border-radius: 0px 0px 5px 5px;
}
