.left-panel {
  background: #F5F5F5;
  border-radius: 5px;
  padding: 20px;
  width: 400px;
  max-height: 700px;
  overflow-y: auto;
  /* height: fit-content; */
  border-radius: 5px;
}
.tabs {
  border-bottom: 1.5px solid rgba(197, 197, 197, 0.5);
  margin-bottom: 20px;
}
.tabs button{
  margin-left: 15px;
  margin-right: 15px;
  padding: 5px;
  background-color: transparent;
}
.tab {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
}
.selected {
  color: #2856AF;
  /* text-decoration: 5px solid underline; */
  border-bottom: 4px solid #2856AF;
  border-radius: 3px;
}