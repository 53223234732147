.file-info {
    width: 100%;
    height: 100%;
    color: #565656;
    padding: 2%;
    box-shadow: 1px 1px 5px 5px lightgray;
}
.name-upload-details {
    width: 70%;
    height: 100%;
    padding: 1% 1% 1% 3%;
}
.cancel-upload {
    width: 10%;
    height: 50%;
    cursor: pointer;
}
.file-name {
    width: 90%;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: bold;
}
.file-name-percentage {
    height: 50%;
    width: 80%;
}
.file-upload-size {
    height: 50%;
    width: 100%;
    font-weight: 400;
}
.progress-bar {
    width: 100%;
    padding: 2%;
    border-radius: 5px;
    background-color: #C4C4C4;
}