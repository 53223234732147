.multi-step-progress {
    width: 100%;
    height: 100%;
    /* background-color: lightgray; */
    background-color: #FFFFFF;
}
.multi-step-progress__header-container {
    width: 100%;
    height: 15%;
    background-color: #FFFFFF;
}
.multi-step-progress__container {
    height: 85%;
    width: 100%;
    background-color: white;
}
