.panel-header {
  display: flex;
  background-color: #2856AF;
  width: 100%;
  height: fit-content;
  border-radius: 5px 5px 0px 0px;
  padding: 5px 15px 0px 15px;
  vertical-align: middle;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}
.template-details {
  color: #FFFF;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  width: 35%;
}
#progressbar {
  background-color: #FFFF;
  border-radius: 15px;
  /* (height of inner div) / 2 + padding */
  padding: 3px;
  width: 200px
}
#progressbar>div {
  background-color: #16C612;
  width: 53%;
  /* Adjust with JavaScript */
  height: 10px;
  border-radius: 15px;
}
.progress-icon {
  margin-right: 10px;
}

.template-progress {
  margin-left: 20px;
}

.panel-segmented {
  border: 1pt solid #2f65d3;
  margin-bottom: 5px;
  border-radius: 5px;
  padding: 5px;
}

.panel-segmented > .ant-segmented > .ant-segmented-group > .ant-segmented-item-selected {
  background: #2f65d3;
  padding: 10px;
  color: white;
}

.panel-segmented > .ant-segmented > .ant-segmented-group > .ant-segmented-thumb {
  background: #2f65d3;
}

.panel-segmented > .ant-segmented > .ant-segmented-group > .ant-segmented-item {
  padding: 10px;
}


.ant-tooltip-inner {
  color: black;
  background-color: white;
}

.ant-tooltip-placement-bottom .ant-tooltip-arrow, .ant-tooltip-placement-bottomLeft .ant-tooltip-arrow, .ant-tooltip-placement-bottomRight .ant-tooltip-arrow {
  border-bottom-color: white;
}
.ant-tooltip-arrow::before{
  background-color: white;
}